import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { Subject } from "rxjs/Subject";
import { CanonicalService } from "../shared/canonical.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-candc",
  templateUrl: "./candc.component.html",
  styleUrls: ["./candc.component.scss"],
})
export class candcComponent implements OnInit, AfterViewInit, OnDestroy {
  private unSubscribe = new Subject<boolean>();
  @ViewChild("mltTrnsSldShw", { static: false })
  mltTrnsSldShw: ElementRef<HTMLDivElement>;
  @ViewChild("jrnAnimCont", { static: false })
  jrnAnimCont: ElementRef<HTMLDivElement>;
  @ViewChild("crsCurAnimCont", { static: false })
  crsCurAnimCont: ElementRef<HTMLDivElement>;
  @ViewChild("jrnlAnim", { static: false })
  jrnlAnim: ElementRef<HTMLDivElement>;
  setIntrvArr = [];
  autoScrollInterval: any;
  showPlayer = false;
  activeImage = 0;
  @ViewChild("downContent", { static: false })
  downContent: ElementRef<HTMLDivElement>;
  activeColor = 1;
  images = [
    "./../assets/images/c and c image4.png",
    "./../assets/images/c and c image7.png",
  ];
  intervalId: any;

  constructor(private canonicalService: CanonicalService,private router: Router) {}

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
    this.startColorSwitching();
  }

  ngOnDestroy() {
    clearInterval(this.autoScrollInterval);
    document.querySelector("app-navbar > header").classList.remove("home");
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
  startColorSwitching() {
    this.intervalId = setInterval(() => {
      this.activeColor = this.activeColor === 1 ? 2 : 1;
      this.activeImage = (this.activeImage + 1) % this.images.length;
    }, 4000);
  }

  stopColorSwitching() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
  scrollToContent() {
    this.downContent.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  ngAfterViewInit() {
    const header = document.querySelector("app-navbar > header");
    if (header) {
      header.classList.add("home");
    }

    function reveal() {
      const reveals = document.querySelectorAll(".reveal");
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const revealTop = reveals[i].getBoundingClientRect().top;
        const revealPoint = windowHeight - 150;

        if (revealTop < revealPoint) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);

    function enterFromRight() {
      const enterFromRights = document.querySelectorAll(".enter-from-right");
      for (let i = 0; i < enterFromRights.length; i++) {
        const windowHeight = window.innerHeight;
        const enterFromRightTop =
          enterFromRights[i].getBoundingClientRect().top;
        const enterFromRightPoint = windowHeight - 150;

        if (enterFromRightTop < enterFromRightPoint) {
          enterFromRights[i].classList.add("active");
        } else {
          enterFromRights[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", enterFromRight);

    function slideIn() {
      const slideIns = document.querySelectorAll(".slide-in");
      for (let i = 0; i < slideIns.length; i++) {
        const windowHeight = window.innerHeight;
        const slideInTop = slideIns[i].getBoundingClientRect().top;
        const slideInPoint = windowHeight - 150;
        if (slideInTop < slideInPoint) {
          slideIns[i].classList.add("active");
        } else {
          slideIns[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", slideIn);
    slideIn();
  }
}
