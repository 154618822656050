import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SendEmails {

    constructor(private http: HttpClient) { }

    sendMail(emailData, type: string) {
        // tslint:disable-next-line:max-line-length
        return this.http.post(`https://us-central1-glbyte-v2.cloudfunctions.net/sendMail?type=${type}`, emailData, { responseType: 'text' });
    }
    
    emailValidation(email) {
        return this.http.post(`https://us-central1-glbyte-v2.cloudfunctions.net/emailValidation`, email, { responseType: 'text' });
    }
    
    sendActivationEmail(data) {
        return this.http.post(`https://us-central1-glbyte-v2.cloudfunctions.net/createTenantNUserEmail`, data, { responseType: 'text' });
    }

    createWebAccount(data) {
        return this.http.post('https://webaccount.glbyte.com/api/CreateWebAccount', data);
    }
}
