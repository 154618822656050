import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import LazyLinePainter from "lazy-line-painter";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/Subject";
import { CanonicalService } from "../shared/canonical.service";
import { CommonService } from "../shared/common.service";
import { SendEmails } from "../shared/send-emails.service";

@Component({
  selector: "app-slaas",
  templateUrl: "./slaas.component.html",
  styleUrls: ["./slaas.component.scss"],
  animations: [
    trigger("popEffect", [
      state(
        "hide",
        style({
          opacity: 0,
          visibility: "hidden",
        })
      ),
      state(
        "show",
        style({
          opacity: 1,
          visibility: "visible",
        })
      ),
      transition("hide => show", [
        style({ visibility: "visible" }),
        style({ opacity: 0, transform: "scale(0)" }),
        animate(300, style({ opacity: 1, transform: "scale(1)" })),
      ]),
      transition("show => hide", [
        animate(300, style({ opacity: 0, transform: "scale(0)" })),
      ]),
    ]),
  ],
})
export class SlaasComponent implements OnInit, OnDestroy, AfterViewInit {
  sideTabActive = 0;
  vdoPlyrState = "hide";
  autoScrollInterval: any;
  vdoPlyrTitle: string;
  private unSubscribe = new Subject<boolean>();
  vdoIconText = "play_arrow";
  isVideoLoaded = false;
  @ViewChild("vdoPlyr", { static: false })
  vdoPlyr: ElementRef<HTMLVideoElement>;
  @ViewChild("sideTabsScroll", { static: false }) sideTabsScroll: ElementRef;
  autoClickObj = {
    stopAutoClick: false,
    setTimeOuts: [],
  };
  srcUrl = {
    DC: {
      name: "Data Capture",
      // tslint:disable-next-line:max-line-length
      url: "https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Fdata-capture.mov?alt=media&token=bf3ee3db-5224-4db0-87c7-647460780371",
    },
    RCNL: {
      name: "Reconciliation",
      // tslint:disable-next-line:max-line-length
      url: "https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Freconciliation.mov?alt=media&token=a18a2457-b66f-4aef-a139-672f99eba8fc",
    },
    ACT: {
      name: "Accounting",
      // tslint:disable-next-line:max-line-length
      url: "https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Faccounting.mov?alt=media&token=a63075f9-a864-454c-bf3c-156d055e1506",
    },
    RPT: {
      name: "Reporting",
      // tslint:disable-next-line:max-line-length
      url: "https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Freporting.mov?alt=media&token=c5688b81-cd3d-4b43-ac49-4f9eb68d7944",
    },
    OVV: {
      name: "Overview",
      // tslint:disable-next-line:max-line-length
      url: "https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Fglbyte-overview.mov?alt=media&token=c19739b4-842e-4b91-a687-75a1cdd6040b",
    },
    EXP: {
      name: "Overview",
      // tslint:disable-next-line:max-line-length
      url: "https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Fglbyte-explanation.mp4?alt=media&token=654f7d54-6761-49c4-8ed4-5437fc0be14a",
    },
  };

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private meta: Meta,
    private title: Title,
    private sendEmails: SendEmails,
    public cmnSrv: CommonService,
    private canonicalService: CanonicalService
  ) {
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  ngAfterViewInit() {
    document.querySelector("app-navbar > header").classList.add("recon");
    if (window.innerWidth >= 992) {
      this.startAutoClick();
    }
    const vdoCont = document.querySelector(".video-player");
    let vdo, vdoIcon;
    if (vdoCont) {
      vdo = vdoCont.querySelector("video");
      vdoIcon = vdoCont.querySelector(".vdo-icon-cont i");
      vdo.addEventListener("play", function () {
        vdoIcon.innerHTML = this.paused ? "pause" : "play_arrow";
        vdoIcon.classList.add("animate-it");
        setTimeout(() => {
          vdoIcon.classList.remove("animate-it");
        }, 500);
      });
      vdo.addEventListener("pause", function () {
        vdoIcon.innerHTML = this.paused ? "pause" : "play_arrow";
        vdoIcon.classList.add("animate-it");
        setTimeout(() => {
          vdoIcon.classList.remove("animate-it");
        }, 500);
      });
    }
  }

  stopAutoSwitch() {
    // Call this to stop automatic switching, if needed
    this.autoClickObj.stopAutoClick = true;
    this.autoClickObj.setTimeOuts.forEach((timeout) => clearTimeout(timeout));
  }

  updateVdoIcon(vdoElm: any) {
    console.log(vdoElm);
  }

  startAutoClick() {
    const tabs = this.sideTabsScroll.nativeElement.querySelectorAll(".tab");
    tabs.forEach((eachElement: HTMLElement, i: number) => {
      const timeOut = setTimeout(() => {
        eachElement.click();
      }, 2000 * i);
      this.autoClickObj.setTimeOuts.push(timeOut);
      if (tabs.length - 1 === i) {
        const inrTimeOut = setTimeout(() => {
          if (!this.autoClickObj.stopAutoClick) {
            this.startAutoClick();
          }
        }, 2000 * (i + 1));
        this.autoClickObj.setTimeOuts.push(inrTimeOut);
      }
    });
  }

  stopAutoClick(evt) {
    if (evt.screenX && evt.screenX !== 0 && evt.screenY && evt.screenY !== 0) {
      this.autoClickObj.stopAutoClick = true;
      this.autoClickObj.setTimeOuts.forEach((each) => clearTimeout(each));
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoScrollInterval);
    document.querySelector("app-navbar > header").classList.remove("home");
    // Cleanup any timeouts when the component is destroyed
    this.stopAutoSwitch();
  }
  showVdoPlyrFn(showOrHide: boolean, src?: string) {
    const nav = document.querySelector("app-navbar header");
    if (showOrHide && this.vdoPlyrState === "hide") {
      this.vdoPlyrTitle = this.srcUrl[src].name;
      this.vdoPlyr.nativeElement.src = this.srcUrl[src].url;
      this.vdoPlyrState = "show";
      document.body.style.overflow = "hidden";
      nav.classList.remove("nav-scroll-up");
      nav.classList.add("nav-scroll-down");
      this.vdoPlyr.nativeElement.addEventListener("loadeddata", () => {
        this.isVideoLoaded = true;
        this.vdoPlyr.nativeElement.muted = true;
        this.vdoPlyr.nativeElement.play();
      });
    } else if (!showOrHide && this.vdoPlyrState === "show") {
      nav.classList.remove("nav-scroll-up");
      nav.classList.add("nav-scroll-down");
      this.vdoPlyrState = "hide";
      document.body.style.overflow = "";
      this.isVideoLoaded = false;
      this.vdoPlyr.nativeElement.pause();
      this.vdoPlyr.nativeElement.currentTime = 0;
    }
  }
  svgAnimationFn(elm, strkWdth) {
    const config = {
      ease: "easeLinear",
      strokeWidth: strkWdth,
      strokeOpacity: 1,
      duration: 50000,
    };
    new LazyLinePainter(elm, config).paint();
  }
}
