import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../config/app.config';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-bi-analytics',
  templateUrl: './bi-analytics.component.html',
  styleUrls: ['./bi-analytics.component.scss']
})
export class BiAnalyticsComponent implements OnInit, AfterViewInit, OnDestroy {

    private unSubscribe = new Subject<boolean>();
    @ViewChild('mltTrnsSldShw', {static: false}) mltTrnsSldShw: ElementRef<HTMLDivElement>;
    @ViewChild('jrnAnimCont', { static: false }) jrnAnimCont: ElementRef<HTMLDivElement>
    @ViewChild('crsCurAnimCont', { static: false }) crsCurAnimCont: ElementRef<HTMLDivElement>
    @ViewChild('jrnlAnim', { static: false }) jrnlAnim: ElementRef<HTMLDivElement>
    setIntrvArr = [];
    autoScrollInterval: any;
    showPlayer = false;
  
    constructor(
      private router: Router,
      private meta: Meta,
      private title: Title,
      private render: Renderer2,
      private canonicalService: CanonicalService
    ) {
      // router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      //   this.navigationInterceptor(evt);
      // });
    }
  
    ngOnInit() {
      this.canonicalService.createLinkForCanonicalURL();
    }
  
    ngOnDestroy() {
      clearInterval(this.autoScrollInterval);
      document.querySelector('app-navbar > header').classList.remove('home');
    }
  
    ngAfterViewInit() {
      const header = document.querySelector('app-navbar > header');
      if (header) {
        header.classList.add('home');
      }
    
  
      function reveal() {
        const reveals = document.querySelectorAll('.reveal');
        for (let i = 0; i < reveals.length; i++) {
          const windowHeight = window.innerHeight;
          const revealTop = reveals[i].getBoundingClientRect().top;
          const revealPoint = windowHeight - 150;
  
          if (revealTop < revealPoint) {
            reveals[i].classList.add('active');
          } else {
            reveals[i].classList.remove('active');
          }
        }
      }
  
      window.addEventListener('scroll', reveal);
  
      function enterFromRight() {
        const enterFromRights = document.querySelectorAll('.enter-from-right');
        for (let i = 0; i < enterFromRights.length; i++) {
          const windowHeight = window.innerHeight;
          const enterFromRightTop = enterFromRights[i].getBoundingClientRect().top;
          const enterFromRightPoint = windowHeight - 150;
  
          if (enterFromRightTop < enterFromRightPoint) {
            enterFromRights[i].classList.add('active');
          } else {
            enterFromRights[i].classList.remove('active');
          }
        }
      }
  
      window.addEventListener('scroll', enterFromRight);
  
      function slideIn() {
        const slideIns = document.querySelectorAll('.slide-in');
        for (let i = 0; i < slideIns.length; i++) {
          const windowHeight = window.innerHeight;
          const slideInTop = slideIns[i].getBoundingClientRect().top;
          const slideInPoint = windowHeight - 150;
          if (slideInTop < slideInPoint) {
            slideIns[i].classList.add('active');
          } else {
            slideIns[i].classList.remove('active');
          }
        }
      }
  
      window.addEventListener('scroll', slideIn);
      slideIn();
    }
  }
  