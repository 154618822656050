import { Component, AfterViewInit, ViewChild, ElementRef, OnDestroy, Renderer2, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfig } from '../config/app.config';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-erp-epm',
  templateUrl: './erp-epm.component.html',
  styleUrls: ['./erp-epm.component.scss']
})
export class ErpEpmComponent implements OnInit, AfterViewInit, OnDestroy {

  private unSubscribe = new Subject<boolean>();
  private stIntrArr = [];
  @ViewChild('ovrAnimCont', {static: false}) ovrAnimCont: ElementRef<HTMLDivElement>;
  @ViewChild('dznCont', { static: false }) dznCont: ElementRef<HTMLDivElement>
  wndRszRemEvnt: () => void;

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private render: Renderer2,
    private canonicalService: CanonicalService
  ) {
    this.router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.magnify('img-zoom', 3);
    }, 100);
    this.reszOvrAnim();
    this.reszDznAnim();
    this.wndRszRemEvnt = this.render.listen(window, 'resize', () => {
      this.reszOvrAnim();
      this.reszDznAnim();
    });
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      if (evt.urlAfterRedirects === '/' + AppConfig.routes.erpEpm) {
        this.title.setTitle('ERP System Integration | EPM Automate Integration - Glbyte');
        this.meta.updateTag({
          name: 'description',
          content: 'ERP/EPM Integration - Glbyte provides access to push secured data from glbyte to any ERP/EPM system in realtime, and also integrate AP Invoices, AR Invoices, etc.'
        });
      }
    }
  }

  magnify(imgID, zoom) {
    let img, glass, w, h, bw;
    img = document.getElementById(imgID);
    /*create magnifier glass:*/
    glass = document.createElement('DIV');
    glass.setAttribute('class', 'img-magnifier-glass');
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = `url(${img.src})`;
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize = (img.width * zoom) + 'px ' + (img.height * zoom) + 'px';
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener('mousemove', moveMagnifier);
    img.addEventListener('mousemove', moveMagnifier);
    glass.addEventListener('mouseout', _ => glass.style.visibility = 'hidden');
    img.addEventListener('mouseout', _ => glass.style.visibility = 'hidden');
    /*and also for touch screens:*/
    glass.addEventListener('touchmove', moveMagnifier);
    img.addEventListener('touchmove', moveMagnifier);
    function moveMagnifier(e) {
      glass.style.visibility = 'visible';
      let pos, x, y;
      /*prevent any other actions that may occur when moving over the image*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;
      /*prevent the magnifier glass from being positioned outside the image:*/
      if (x > img.width - (w / zoom)) { x = img.width - (w / zoom); }
      if (x < w / zoom) { x = w / zoom; }
      if (y > img.height - (h / zoom)) { y = img.height - (h / zoom); }
      if (y < h / zoom) { y = h / zoom; }
      /*set the position of the magnifier glass:*/
      glass.style.left = (x - w) + 'px';
      glass.style.top = (y - h) + 'px';
      /*display what the magnifier glass 'sees':*/
      glass.style.backgroundPosition = '-' + ((x * zoom) - w + bw) + 'px -' + ((y * zoom) - h + bw) + 'px';
    }
    function getCursorPos(e) {
      let a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  ngOnDestroy() {
    this.wndRszRemEvnt();
  }

  reszOvrAnim() {
    const cont: HTMLDivElement = this.ovrAnimCont.nativeElement.querySelector('.ovrvw-anim-cont');
    // tslint:disable-next-line:max-line-length
    const scale = Math.min(this.ovrAnimCont.nativeElement.clientWidth / (cont.clientWidth + 30), this.ovrAnimCont.nativeElement.clientHeight / cont.clientHeight);
    cont.style.transform = `scale(${scale})`;
  }

  reszDznAnim() {
    const cont: HTMLDivElement = this.dznCont.nativeElement.querySelector('.dzn');
    // tslint:disable-next-line:max-line-length
    const scale = Math.min(this.dznCont.nativeElement.clientWidth / (cont.clientWidth + 30), this.dznCont.nativeElement.clientHeight / cont.clientHeight);
    cont.style.transform = `scale(${scale})`;
  }

}
