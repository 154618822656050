import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { SendEmails } from 'src/app/shared/send-emails.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements AfterViewInit {
  email: string;
  emailLoading = false;
  emailInvaid = false;

  constructor(private sendEmails: SendEmails, private router: Router) { }

  sendEmail() {
    this.emailInvaid = false;
    if (new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(this.email)) {
      this.emailLoading = true;
      this.sendEmails.emailValidation(this.email).subscribe(resVld => {
        if (resVld === 'true') {
          this.emailInvaid = false;
          this.sendEmails.sendMail(this.email, 'quick-email').subscribe(res => {
            if (res.includes('Email Sent')) {
              this.router.navigate(['request-demo'], { queryParams: { thankyou: true } });
            }
          }, () => this.emailLoading = false);
        } else {
          this.emailLoading = false;
          this.emailInvaid = true;
        }
      });
    } else {
      this.emailInvaid = true;
    }
  }

  ngAfterViewInit() {
    // START Cookie
    const cookieDialog: HTMLElement = document.querySelector('.cookie-notification');
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + 'cookie_status' + '=');
    if (parts.length === 2 && parts.pop().split(';').shift()) {
      cookieDialog.style.display = 'none';
    }
    // END Cookie
  }

  closeCookies(isAccepted) {
    document.cookie = `cookie_status=${isAccepted}`;
    const cookieDialog: HTMLElement = document.querySelector('.cookie-notification');
    cookieDialog.style.opacity = '0';
    setTimeout(() => {
      cookieDialog.style.display = 'none';
    }, 1000);
  }
}
