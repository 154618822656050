import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Subject } from "rxjs/Subject";
import { CanonicalService } from "../shared/canonical.service";

@Component({
  selector: "app-fcm",
  templateUrl: "./fcm.component.html",
  styleUrls: ["./fcm.component.scss"],
})
export class fcmComponent implements OnInit, AfterViewInit, OnDestroy {
  private unSubscribe = new Subject<boolean>();
  @ViewChild("downContent", { static: false })
  downContent: ElementRef<HTMLDivElement>;
  activeIndex = 0;
  intervalId: any;

  constructor(private canonicalService: CanonicalService) {}

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
    this.startAutoScroll();
  }

  ngAfterViewInit() {
    this.setupHoverEvents();
    this.setupClickEvents();
  }

  scrollToContent() {
    this.downContent.nativeElement.scrollIntoView({ behavior: "smooth" });
  }

  ngOnDestroy() {
    this.clearIntervals();
  }

  startAutoScroll() {
    this.intervalId = setInterval(() => {
      this.moveToNextContent();
    }, 2000);
  }

  moveToNextContent() {
    this.activeIndex = (this.activeIndex + 1) % 6;
    this.updateActiveContent();
  }

  stopAutoScroll() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
  navigateTo(index: number) {
    this.stopAutoScroll();
    this.activeIndex = index;
    this.updateActiveContent();
  }

  setupHoverEvents() {
    const buttons = document.querySelectorAll(".fcmch1");
    const contents = document.querySelectorAll(".homecontent");

    buttons.forEach((button) => {
      button.addEventListener("mouseenter", () => this.stopAutoScroll());
      button.addEventListener("mouseleave", () => this.startAutoScroll());
    });

    contents.forEach((content) => {
      content.addEventListener("mouseenter", () => this.stopAutoScroll());
      content.addEventListener("mouseleave", () => this.startAutoScroll());
    });
  }

  setupClickEvents() {
    const buttons = document.querySelectorAll(".fcmch1");

    buttons.forEach((button, index) => {
      button.addEventListener("click", () => {
        this.stopAutoScroll();
        this.activeIndex = index;
        this.updateActiveContent();
      });
    });
  }

  clearIntervals() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateActiveContent() {
    const buttons = document.querySelectorAll(".fcmch1");
    const container = document.querySelector(".homecontent-container");

    buttons.forEach((button, index) => {
      button.classList.toggle("active", index === this.activeIndex);
    });

    if (container) {
      container.scrollTo({
        left: container.clientWidth * this.activeIndex,
        behavior: "smooth",
      });
    }
  }
}
