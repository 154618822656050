import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { AppConfig } from '../config/app.config';
import { CanonicalService } from '../shared/canonical.service';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-data-modelling',
  templateUrl: './data-modelling.component.html',
  styleUrls: ['./data-modelling.component.scss']
})
export class DataModellingComponent implements OnInit, AfterViewInit, OnDestroy {

  private unSubscribe = new Subject<boolean>();
  mainTabActive = 0;
  sideTabActive = 0;
  slidePointPrnt = 0;
  slidePoint = 0;
  showPlayer = false;
  @ViewChild('mainTabsScroll', { static: false }) mainTabsScroll: ElementRef;
  @ViewChild('sideTabsScroll', { static: false }) sideTabsScroll: ElementRef;
  @ViewChild('sideTabsScroll2', { static: false }) sideTabsScroll2: ElementRef;
  autoClickObj = {
    stopAutoClick: false,
    tabNum: 0,
    setTimeOuts: []
  };

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    public cmnSrv: CommonService,
    private canonicalService: CanonicalService
  ) {
    router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  ngAfterViewInit() {
    document.querySelector('app-navbar > header').classList.add('dt-cp');
    if (window.innerWidth >= 790) {
      this.startAutoClick();
    }
  }

  // Auto clicks to block -  "Collect data from any source" for tab
  startAutoClick() {
    const tabCont: any = this.autoClickObj.tabNum === 0 ? this.sideTabsScroll : this.sideTabsScroll2,
      sideTab = tabCont.nativeElement.querySelectorAll('.tab');
    sideTab.forEach((eachElement: HTMLElement, i: number) => {
      const timeOut = setTimeout(() => {
        eachElement.click();
      }, 2000 * i);
      this.autoClickObj.setTimeOuts.push(timeOut);
      if ((sideTab.length - 1) === i) {
        const inrTimeOut = setTimeout(() => {
          this.autoClickObj.tabNum = this.autoClickObj.tabNum === 0 ? 1 : 0;
          this.mainTabsScroll.nativeElement.querySelectorAll('.tab')[this.autoClickObj.tabNum].click();
          if (!this.autoClickObj.stopAutoClick) {
            this.startAutoClick();
          }
          this.autoClickObj.setTimeOuts.push(inrTimeOut);
        }, 2000 * (i + 1));
      }
    });
  }

  stopAutoClick(evt) {
    if (evt.screenX && evt.screenX !== 0 && evt.screenY && evt.screenY !== 0) {
      this.autoClickObj.stopAutoClick = true;
      this.autoClickObj.setTimeOuts.forEach(each => clearTimeout(each));
    }
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      if (evt.urlAfterRedirects === '/' + AppConfig.routes.dataCapture) {
        this.title.setTitle('Big Data Integration Tool | Data Capture Tools - Glbyte');
        this.meta.updateTag({
          name: 'description',
          content: 'Move data from any source and capture data without doing single line of code.'
        });
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    document.querySelector('app-navbar > header').classList.remove('dt-cp');
  }

  tabsScroll(elem: HTMLDivElement, isPrev: boolean, tabLen: number, isPrnt = false) {
    let slidePoint = isPrnt ? this.slidePointPrnt : this.slidePoint;
    if (!isPrev && (Math.abs(slidePoint) > (tabLen - 1)) || (isPrev && Math.abs(slidePoint) <= 0)) {
      return;
    }
    slidePoint = isPrev ? slidePoint + 1 : slidePoint - 1;
    isPrnt ? this.slidePointPrnt = slidePoint : this.slidePoint = slidePoint;
    elem.style.transform = `translateX(${slidePoint * 100}%)`;
  }

}
