import { Component, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements AfterViewInit, OnDestroy {

  ngAfterViewInit() {
    document.querySelector('app-navbar > header').classList.add('othrs');
  }

  ngOnDestroy() {
    document.querySelector('app-navbar > header').classList.remove('othrs');
  }

}