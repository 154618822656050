import { Component, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewInit, OnDestroy{

  winScrolList: any;
  activeLink: string;

  constructor(
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngAfterViewInit() {
    let lastScrollTop = 0;
    const nav = document.querySelector('app-navbar header');
    this.winScrolList = this.renderer.listen(window, 'scroll', (evt) => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        nav.classList.remove('nav-scroll-up');
        nav.classList.add('nav-scroll-down');
      } else {
        nav.classList.remove('nav-scroll-down');
        nav.classList.add('nav-scroll-up');
        /* if (scrollTop === 0) {
          nav.classList.add('trans');
        } else {
          nav.classList.remove('trans');
        } */
      }
      lastScrollTop = scrollTop;
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveLink(event.url);
      }
    });
  }

  ngOnDestroy() {
    if (this.winScrolList) {
      this.winScrolList();
    }
  }

  setActiveLink(url: string) {
    if (url.endsWith('app-slakas')) {
      this.activeLink = 'app-slaas';
    } else if (url.endsWith('app-candc')) {
      this.activeLink = 'app-candc';
    } else if (url.endsWith('app-fcm')) {
      this.activeLink = 'app-fcm';
    } else if (url.endsWith('app-bi-analytics')) {
      this.activeLink = 'app-bi-analytics';
    }
  }

  dropDown(isOpen) {
    const popup = document.querySelector('.pop-up-menu');
    if (isOpen) {
      popup.classList.add('show');
      setTimeout(() => {
        popup.classList.add('slide-from-top');
      }, 10);
    } else {
      popup.classList.remove('slide-from-top');
      setTimeout(() => {
        popup.classList.remove('show');
      }, 500);
    }
  }

  hideDropDown() {
    const elem: HTMLElement = document.querySelector('.nst-drp-dwn');
    const elem2: HTMLElement = document.querySelector('.nst-drp-dwn-bg');
    elem.style.visibility = 'hidden';
    elem2.style.visibility = 'hidden';
    setTimeout(() => {
      elem.style.visibility = '';
      elem2.style.visibility = '';
    }, 1000);
  }
   
}
