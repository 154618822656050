import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../config/app.config';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit, AfterViewInit, OnDestroy {

  private unSubscribe = new Subject<boolean>();
  @ViewChild('mltTrnsSldShw', {static: false}) mltTrnsSldShw: ElementRef<HTMLDivElement>;
  @ViewChild('jrnAnimCont', { static: false }) jrnAnimCont: ElementRef<HTMLDivElement>
  @ViewChild('crsCurAnimCont', { static: false }) crsCurAnimCont: ElementRef<HTMLDivElement>
  @ViewChild('jrnlAnim', { static: false }) jrnlAnim: ElementRef<HTMLDivElement>
  wndRszRemEvnt: () => void;
  setIntrvArr = [];
  showPlayer = false;

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private render: Renderer2,
    private canonicalService: CanonicalService
  ) {
    router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  ngAfterViewInit() {
    document.querySelector('app-navbar > header').classList.add('act');
    // this.mltTrnsAnimation();
    setTimeout(() => {
      this.jrnlAnimation(1);
      this.playCrsCurAnim();
    }, 2000);
    this.jrnlAnimResize();
    this.wndRszRemEvnt = this.render.listen(window, 'resize', () => {
      this.jrnlAnimResize();
    });
  }

  jrnlAnimResize() {
    const cont: HTMLDivElement = this.jrnAnimCont.nativeElement.querySelector('.jrn-anim-cont');
    const crsCur: HTMLDivElement = document.querySelector('.crs-cur');
    // tslint:disable-next-line:max-line-length
    const scale = Math.min(this.jrnAnimCont.nativeElement.clientWidth / (cont.clientWidth + 30), this.jrnAnimCont.nativeElement.clientHeight / cont.clientHeight);
    cont.style.transform = `scale(${scale})`;
    crsCur.style.transform = `scale(${scale})`;
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      if (evt.urlAfterRedirects === '/' + AppConfig.routes.accounting) {
        this.title.setTitle('Accounting Automation | Finance, Continuous &  Receivable Automation');
        this.meta.updateTag({
          name: 'description',
          content: 'Get real time accounting, revenue accounting & accountiong automation from Glbyte. We offer rule based accounting engine, flexible accounting rules, integration with any ERP etc.'
        });
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    this.wndRszRemEvnt();
    document.querySelector('app-navbar > header').classList.remove('act');
    this.setIntrvArr.forEach(each => clearInterval(each));
  }

  mltTrnsAnimation() {
    let cnt = 1;
    const intr = setInterval(() => {
      Array.from(this.mltTrnsSldShw.nativeElement.children).forEach((each: any) => each.style.transform = `translateX(-${100 * cnt}%)`);
      cnt = cnt < 2 ? cnt + 1 : 0;
    }, 4000);
    this.setIntrvArr.push(intr);
  }

  jrnlAnimation(scr) {
    if (scr >= 3) {
      setTimeout(() => {
        this.playJrnlAnimation();
      }, 2000);
      return;
    }
    const clickableRow = this.jrnlAnim.nativeElement.querySelector(`.scr-${scr} .clk-efct`);
    let cnt = scr;
    if (clickableRow) {
      clickableRow.classList.add('active');
    }
    setTimeout(() => {
      Array.from(this.jrnlAnim.nativeElement.children).forEach((each: any) => each.style.transform = `translateX(-${100 * cnt}%)`);
      if (clickableRow) {
        this.jrnlAnim.nativeElement.querySelector(`.scr-${scr} .clk-efct`).classList.remove('active');
      }
      cnt = cnt < 3 ? cnt + 1 : 0;
      if (cnt) {
        setTimeout(() => {
          this.jrnlAnimation(cnt);
        }, 2000);
      }
    }, 2000);
  }

  playJrnlAnimation() {
    Array.from(this.jrnlAnim.nativeElement.children).forEach((each: any) => each.style.transform = `translateX(-0%)`);
    setTimeout(() => {
      this.jrnlAnimation(1);
    }, 2000);
  }

  playCrsCurAnim() {
    this.crsCurAnimCont.nativeElement.classList.add('strt-anim');
    const intr = setInterval(() => {
      this.crsCurAnimCont.nativeElement.classList.remove('strt-anim');
      setTimeout(_ => {
        this.crsCurAnimCont.nativeElement.classList.add('strt-anim');
      }, 100);
    }, 9 * 1000);
  }

}
