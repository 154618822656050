import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SendEmails } from './shared/send-emails.service';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { CommonService } from './shared/common.service';
import { Error404Component } from './error404/error404.component';
import { APP_CONFIG, AppConfig } from './config/app.config';
import { DataModellingComponent } from './data-modelling/data-modelling.component';
import { ReconciliationComponent } from './reconciliation/reconciliation.component';
import { AccountingComponent } from './accounting/accounting.component';
import { ReportsComponent } from './reports/reports.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TeamComponent } from './team/team.component';
import { ErpEpmComponent } from './erp-epm/erp-epm.component';
import { CookieComponent } from './cookie/cookie.component';
import { IndustriesComponent } from './industries/industries.component';
import { LinksSidebarComponent } from './links-sidebar/links-sidebar.component';
import { VideoPlayerComponent } from './shared/video-player/video-player.component';
import { ScheduleDemoComponent } from './schedule-demo/schedule-demo.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CookiePreferencesComponent } from './cookie-preferences/cookie-preferences.component';
import { SlaasComponent } from './slaas/slaas.component';
import { fcmComponent } from './fcm/fcm.component';
import { BiAnalyticsComponent } from './bi-analytics/bi-analytics.component';
import { candcComponent } from './candc/candc.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    Error404Component,
    DataModellingComponent,
    ReconciliationComponent,
    AccountingComponent,
    ReportsComponent,
    ContactUsComponent,
    TeamComponent,
    ErpEpmComponent,
    CookieComponent,
    IndustriesComponent,
    LinksSidebarComponent,
    VideoPlayerComponent,
    ScheduleDemoComponent,
    SignUpComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    CookiePreferencesComponent,
    SlaasComponent,
    fcmComponent,
    BiAnalyticsComponent,
    candcComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyD3bWe2qRpJXh48TN5Qk9_djvh9XRaxPyg",
      authDomain: "glbyterapid.firebaseapp.com",
      projectId: "glbyterapid",
      storageBucket: "glbyterapid.appspot.com",
      messagingSenderId: "765811200389",
      appId: "1:765811200389:web:22f75a9dc8951b134974ec",
      measurementId: "G-YVETLVZ3VD"
    })
  ],
  providers: [
    { provide: APP_CONFIG, useValue: AppConfig },
    SendEmails,
    CommonService,
    AngularFireAuth
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
