import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Title, Meta } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as countries from './country.json';
import { NgForm } from '@angular/forms';
import { AppConfig } from '../config/app.config.js';
import { SendEmails } from '../shared/send-emails.service';
import { CanonicalService } from '../shared/canonical.service';

class ContactUs {
  fullName: string;
  email: string;
  company: string;
  country: string;
  phoneNumber: number;
  comments: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit, AfterViewInit, OnDestroy {

  private unSubscribe = new Subject<boolean>();
  countries: any;
  contactUs: ContactUs = new ContactUs();
  formError: string;
  emailLoading = false;
  emailSent = false;
  demo = false;
  @ViewChild('cntctForm', { static: false }) cntctForm: NgForm;

  constructor(
    private router: Router,
    private title: Title,
    private meta: Meta,
    private sendEmails: SendEmails,
    private route: ActivatedRoute,
    private canonicalService: CanonicalService
  ) {
    this.router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngAfterViewInit() {
    document.querySelector('app-navbar > header').classList.add('cnt-us');
    if (this.cntctForm) {
      this.cntctForm.valueChanges.subscribe(() => this.formError = null);
    }
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
    this.route.queryParams.subscribe((params) => {
      this.demo = this.route.snapshot.url[0].path.includes('request-demo') || false;
      this.emailSent = params.thankyou || false;
    });
    const countriesJson: any = countries;
    this.countries = countriesJson.default;
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      this.title.setTitle(evt.urlAfterRedirects === '/' + AppConfig.routes.contactUs ? 'Contact us for automate your revenue operations with glByte.' : 'Request Demo');
      this.meta.updateTag({
        name: 'description',
        // tslint:disable-next-line:max-line-length
        content: evt.urlAfterRedirects === '/' + AppConfig.routes.contactUs ? 'Contact us - Glbyte is providing automation service to enterprise to automate their financial revenue.' : 'Do you have a question or need more info? Please enter your information and describe your inquiry, and we’ll get back to you as soon as possible. Thanks!'
      });
    }
  }

  ngOnDestroy() {
    document.querySelector('app-navbar > header').classList.remove('cnt-us');
  }

  onSubmit(frm: NgForm) {
    if (frm.valid) {
      this.formError = null;
      if (!/^[A-Za-z]+$/.test(this.contactUs.fullName)) {
        this.formError = 'Full Name is not valid';
        return false;
      } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.contactUs.email)) {
        this.formError = 'Please enter valid email address';
        return false;
      } else {
        this.emailLoading = true;
        this.sendEmails.emailValidation(this.contactUs.email).subscribe(resVld => {
          if (resVld === 'true') {
            this.formError = null;
            this.sendEmails.sendMail(this.contactUs, this.demo ? 'demo' : 'contact-us').subscribe(res => {
              if (res.includes('Email Sent')) {
                this.emailLoading = false;
                this.emailSent = true;
                frm.reset();
              }
            }, () => this.emailLoading = false);
          } else {
            this.emailLoading = false;
            this.formError = 'Please enter valid email address';
          }
        }, () => {
          this.emailLoading = false;
          this.formError = 'Unable to verify email address';
        });
      }
    } else {
      this.formError = 'All details are required';
    }
  }

}
