import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { SendEmails } from '../shared/send-emails.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit {

  @ViewChild('fullNameInput', { static: false }) fullNameInput: ElementRef<HTMLDivElement>;
  emailSent = false;
  emailLoading = false;
  formError = '';
  signUp = {
    companyName: '',
    email: '',
    password: ''
  }

  constructor(
    private sendEmails: SendEmails,
    private angularFireAuth: AngularFireAuth,
    private http: HttpClient
  ) { }

  ngAfterViewInit() {
    this.fullNameInput.nativeElement.focus();
  }

  ngOnInit() {
  }

  async onSubmit(frm: NgForm) {
    //Return if API is in process
    if (this.emailLoading) return;

    this.formError = '';
    this.emailSent = false;
    this.emailLoading = true;

    //Check if form is filled
    if (frm.invalid) {
      this.formError = 'Please enter the required details';
      return;
    }

    //Full name validation
    if (!this.signUp.companyName) {
      this.formError = 'Full Name is not valid';
      return false;
    }

    //Email validation
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.signUp.email)) {
      this.formError = 'Please enter valid email address';
      return false;
    }

    //Password validation
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.signUp.password)) {
      this.formError = 'Password should contain at least eight characters, one number, one uppercase, one lowercase and one special characters';
      return false;
    }

    try {
      await this.angularFireAuth.createUserWithEmailAndPassword(this.signUp.email, this.signUp.password);
      await this.http.post('https://us-central1-glbyterapid.cloudfunctions.net/sendEmailVerification', this.signUp).toPromise();
      this.emailSent = true;
      this.emailLoading = false;
      frm.reset();
    } catch (error) {
      this.formError = error;
      this.emailLoading = false;
      console.error('Error occured while sign up', error);
    }

    // this.emailLoading = true;
    //Validate email at server
    /* this.sendEmails.emailValidation(this.signUp.email).subscribe(resVld => {
      if (resVld === 'true') {
        this.formError = null;
        //Create default tenant/user account Email
        const obj = {
          businessCompanyName: this.signUp.companyName,
          businessEmail: this.signUp.email
        }
        this.sendEmails.sendActivationEmail(this.signUp).subscribe(res => {
          if (res == 'Email Sent') {
            this.emailSent = true;
            frm.reset();
          } else {
            this.formError = 'Error occured while sign up';
          }
          this.emailLoading = false;
        }, err => {
          console.log(err);
          this.emailLoading = false;
          this.formError = 'Error occured while sign up';
        });
      } else {
        this.emailLoading = false;
        this.formError = 'Please enter valid email address';
      }
    }, () => {
      this.emailLoading = false;
      this.formError = 'Unable to verify email address';
    }); */
  }

  createWebAccount(data) {
    return new Observable(observer => {
      fetch('https://webaccount.glbyte.com/api/CreateWebAccount', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        referrerPolicy: 'same-origin'
      }).then(res => {
        observer.next(true);
      }).catch(err => {
        console.error('Error occured while CreateWebAccount', err);
        observer.next(false);
      });
    });
  }

}
