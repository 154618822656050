import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';

@Component({
    selector: 'app-video-player',
    template: `
    <section class="video-player" [@popEffect]="vdoPlyrState">
        <div class="vdo-txt-cont">
            <div class="actions">
                <h4>{{vdoPlyrTitle}}</h4>
                <i class="material-icons" (click)="showVdoPlyrFn(false)">close</i>
            </div>
            <div *ngIf="!isVideoLoaded" class="loading-cont">
                <p>Loading...</p>
            </div>
            <div class="vdo-icon-cont">
                <i class="material-icons">play_arrow</i>
                <video #vdoPlyr controls muted></video>
            </div>
        </div>
    </section>
    `,
    styles: [
        `
        .video-player {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.video-player .vdo-txt-cont {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  position: relative;
}
.video-player .vdo-icon-cont {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-player .vdo-icon-cont i {
  position: absolute;
  font-size: 3rem;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  opacity: 0;
}
.video-player .vdo-icon-cont i.animate-it {
  animation: scaleUpHide 0.5s ease-in-out forwards;
}
.video-player .actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: white;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid lightgray;
  color: #417efe;
}
.video-player .actions h4 {
  margin-bottom: 0;
}
.video-player .actions i {
  cursor: pointer;
}
.video-player .actions i:hover {
  background: #417efe;
  color: white;
  border-radius: 50%;
}
.video-player video {
  outline: none;
  width: 100%;
}
.video-player .loading-cont {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video-player .loading-cont p {
  margin-bottom: 0;
}
        `
    ],
    animations: [
        trigger('popEffect', [
            state('hide', style({
                opacity: 0,
                visibility: 'hidden'
            })),
            state('show', style({
                opacity: 1,
                visibility: 'visible'
            })),
            transition('hide => show', [
                style({ visibility: 'visible' }),
                style({ opacity: 0, transform: 'scale(0)' }),
                animate(300, style({ opacity: 1, transform: 'scale(1)' })),
            ]),
            transition('show => hide', [
                animate(300, style({ opacity: 0, transform: 'scale(0)' }))
            ])
        ])
    ]
})
export class VideoPlayerComponent {

    @ViewChild('vdoPlyr', { static: false }) vdoPlyr: ElementRef<HTMLVideoElement>;
    togglePlayerVar: boolean;
    @Input()
    set togglePlayer(value: boolean) {
        this.togglePlayerVar = value;
        this.showVdoPlyrFn(value, this.vdoUrl);
    }
    get togglePlayer(): boolean {
        return this.togglePlayerVar;
    }
    @Output() togglePlayerChange = new EventEmitter<boolean>();
    @Input() vdoUrl: string;
    vdoPlyrState = 'hide';
    vdoPlyrTitle: string;
    isVideoLoaded = false;
    srcUrl = {
        DC: {
            name: 'Data Capture',
            // tslint:disable-next-line:max-line-length
            url: 'https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Fdata-capture.mov?alt=media&token=bf3ee3db-5224-4db0-87c7-647460780371'
        },
        RCNL: {
            name: 'Reconciliation',
            // tslint:disable-next-line:max-line-length
            url: 'https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Freconciliation.mov?alt=media&token=a18a2457-b66f-4aef-a139-672f99eba8fc'
        },
        ACT: {
            name: 'Accounting',
            // tslint:disable-next-line:max-line-length
            url: 'https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Faccounting.mov?alt=media&token=a63075f9-a864-454c-bf3c-156d055e1506'
        },
        RPT: {
            name: 'Reporting',
            // tslint:disable-next-line:max-line-length
            url: 'https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Freporting.mov?alt=media&token=c5688b81-cd3d-4b43-ac49-4f9eb68d7944'
        },
        OVV: {
            name: 'Overview',
            // tslint:disable-next-line:max-line-length
            url: 'https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Fglbyte-overview.mov?alt=media&token=c19739b4-842e-4b91-a687-75a1cdd6040b'
        },
        EXP: {
            name: 'Overview',
            // tslint:disable-next-line:max-line-length
            url: 'https://firebasestorage.googleapis.com/v0/b/glbyte-v2.appspot.com/o/vdos%2Fglbyte-explanation.mp4?alt=media&token=654f7d54-6761-49c4-8ed4-5437fc0be14a'
        }
    };

    showVdoPlyrFn(showOrHide: boolean, src?: string) {
        const nav = document.querySelector('app-navbar header');
        if (showOrHide && this.vdoPlyrState === 'hide') {
            this.vdoPlyrTitle = this.srcUrl[src].name;
            this.vdoPlyr.nativeElement.src = this.srcUrl[src].url;
            this.vdoPlyrState = 'show';
            document.body.style.overflow = 'hidden';
            nav.classList.remove('nav-scroll-up');
            nav.classList.add('nav-scroll-down');
            this.vdoPlyr.nativeElement.addEventListener('loadeddata', () => {
                this.isVideoLoaded = true;
                this.vdoPlyr.nativeElement.muted = true;
                this.vdoPlyr.nativeElement.play();
            });
        } else if (!showOrHide && this.vdoPlyrState === 'show') {
            nav.classList.remove('nav-scroll-up');
            nav.classList.add('nav-scroll-down');
            this.vdoPlyrState = 'hide';
            document.body.style.overflow = '';
            this.isVideoLoaded = false;
            this.vdoPlyr.nativeElement.pause();
            this.vdoPlyr.nativeElement.currentTime = 0;
            this.togglePlayerChange.emit(false);
        }
    }

}
