import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { AppConfig } from '../config/app.config';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {

  private unSubscribe = new Subject<boolean>();
  @ViewChild('intDshslideShowCont', {static: false}) intDshslideShowCont: ElementRef<HTMLDivElement>;
  @ViewChild('pwrfRepslideShowCont', { static: false }) pwrfRepslideShowCont: ElementRef<HTMLDivElement>;
  @ViewChild('ovrVwAnim', { static: false }) ovrVwAnim: ElementRef<HTMLDivElement>
  setIntervalsArr = [];
  wndRszRemEvnt: () => void;
  showPlayer = false;

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private render: Renderer2,
    private canonicalService: CanonicalService
  ) {
    router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  ngAfterViewInit() {
    document.querySelector('app-navbar > header').classList.add('rpt');
    this.intDshBrdAnltAnimation();
    this.pwrfRepAnimation();
    this.repMainAnimResz();
    this.wndRszRemEvnt = this.render.listen(window, 'resize', () => {
      this.repMainAnimResz();
    });
    setTimeout(() => {
      this.playOvrVwAnim();
    }, 100);
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      if (evt.urlAfterRedirects === '/' + AppConfig.routes.reports) {
        this.title.setTitle('Data Visualization Accounting | Realtime Financial Reporting Tools & KPIs');
        this.meta.updateTag({
          name: 'description',
          content: 'Glbyte is providing real time financial reporting & data visualization on any data. Business Analytics includes Reports Repository, Configurable KPI Widgets, etc'
        });
      }
    }
  }

  intDshBrdAnltAnimation() {
    const intr = setInterval(() => {
      const lastElm: any = this.intDshslideShowCont.nativeElement.lastChild;
      const firstElm: any = this.intDshslideShowCont.nativeElement.firstChild;
      lastElm.classList.add('flip-hide');
      setTimeout(() => {
        lastElm.classList.remove('flip-hide');
        this.intDshslideShowCont.nativeElement.insertBefore(lastElm, firstElm);
      }, 500);
    }, 5000);
    this.setIntervalsArr.push(intr);
  }

  pwrfRepAnimation() {
    const intr = setInterval(() => {
      const lastElm: any = this.pwrfRepslideShowCont.nativeElement.lastChild;
      const firstElm: any = this.pwrfRepslideShowCont.nativeElement.firstChild;
      lastElm.classList.add('rot-arnd');
      setTimeout(() => {
        lastElm.classList.remove('rot-arnd');
        this.pwrfRepslideShowCont.nativeElement.insertBefore(lastElm, firstElm);
      }, 500);
    }, 5000);
    this.setIntervalsArr.push(intr);
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    document.querySelector('app-navbar > header').classList.remove('rpt');
    this.setIntervalsArr.forEach(each => clearInterval(each));
    this.wndRszRemEvnt();
  }

  playOvrVwAnim() {
    this.ovrVwAnim.nativeElement.classList.add('stt-anim');
    const intr = setInterval(_ => {
      this.ovrVwAnim.nativeElement.classList.remove('stt-anim');
      setTimeout(() => {
        this.ovrVwAnim.nativeElement.classList.add('stt-anim');
      }, 100);
    }, 6000);
    this.setIntervalsArr.push(intr);
  }

  repMainAnimResz() {
    const cont: HTMLDivElement = this.ovrVwAnim.nativeElement.querySelector('.scr-cont');
    // tslint:disable-next-line:max-line-length
    const scale = Math.min(this.ovrVwAnim.nativeElement.clientWidth / (cont.clientWidth + 30), this.ovrVwAnim.nativeElement.clientHeight / cont.clientHeight);
    cont.style.transform = `scale(${scale})`;
  }
}
