import { Component, OnInit, AfterViewInit } from '@angular/core';

declare const Calendly;

@Component({
  selector: 'app-schedule-demo',
  templateUrl: './schedule-demo.component.html',
  styleUrls: ['./schedule-demo.component.scss']
})
export class ScheduleDemoComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (Calendly) {
      Calendly.initInlineWidget({
        url: 'https://calendly.com/glbyte-demo-schedule/schedule-demo',
        parentElement: document.querySelector('.calendly-inline-widget'),
      });
    }
  }

}
