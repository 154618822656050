import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig = {
    routes: {
        error404: 'not-found',
        dataCapture: 'data-capture',
        reconciliation: 'reconciliation',
        accounting: 'accounting',
        reports: 'dashboard-reports',
        contactUs: 'contact-us',
        requestDemo: 'request-demo',
        scheduleDemo: 'schedule-demo',
        team: 'team',
        erpEpm: 'erp-epm',
        industries: 'industries',
        cookie: 'cookie',
        signUp: 'sign-up',
        privacy:'privacy-policy',
        cookiepolicy:'cookie-policy',
        cookiePrefernces:'cookie-preferences',
        slaas:'app-slaas',
        fcm:'app-fcm',
        bianalytics:'app-bi-analytics',
        candc:'app-candc',
    }
};
