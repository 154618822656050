import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { CanonicalService } from '../shared/canonical.service';
import { CommonService } from '../shared/common.service';
import { SendEmails } from '../shared/send-emails.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('popEffect', [
      state('hide', style({
        opacity: 0,
        visibility: 'hidden'
      })),
      state('show', style({
        opacity: 1,
        visibility: 'visible'
      })),
      transition('hide => show', [
        style({ visibility: 'visible' }),
        style({ opacity: 0, transform: 'scale(0)' }),
        animate(300, style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition('show => hide', [
        animate(300, style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('vdoPlyr', { static: false }) vdoPlyr: ElementRef<HTMLVideoElement>;
  private unSubscribe = new Subject<boolean>();
  vdoIconText = 'play_arrow';
  winScrolList: any;
  autoScrollInterval: any;
  vdoPlyrState = 'hide';
  vdoPlyrTitle: string;
  isVideoLoaded = false;
  email: string;
  emailInvaid = false;
  emailLoading = false;

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    private sendEmails: SendEmails,
    public cmnSrv: CommonService,
    private canonicalService: CanonicalService
  ) {
    this.router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      if (evt.urlAfterRedirects === '/') {
        this.title.setTitle('Artificial Intelligence Cash Automation | AI Reconciliation');
        this.meta.updateTag({
          name: 'description',
          content: 'Glbyte takes care of the complete lifecycle of financial transactions and solve challenges of data integration, AI reconciliation delays and real-time reporting.'
        });
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.autoScrollInterval);
    document.querySelector('app-navbar > header').classList.remove('home');
  }

  ngAfterViewInit() {
    const header = document.querySelector('app-navbar > header');
    if (header) {
      header.classList.add('home');
    }
  

    function reveal() {
      const reveals = document.querySelectorAll('.reveal');
      for (let i = 0; i < reveals.length; i++) {
        const windowHeight = window.innerHeight;
        const revealTop = reveals[i].getBoundingClientRect().top;
        const revealPoint = windowHeight - 150;

        if (revealTop < revealPoint) {
          reveals[i].classList.add('active');
        } else {
          reveals[i].classList.remove('active');
        }
      }
    }

    window.addEventListener('scroll', reveal);

    function enterFromRight() {
      const enterFromRights = document.querySelectorAll('.enter-from-right');
      for (let i = 0; i < enterFromRights.length; i++) {
        const windowHeight = window.innerHeight;
        const enterFromRightTop = enterFromRights[i].getBoundingClientRect().top;
        const enterFromRightPoint = windowHeight - 150;

        if (enterFromRightTop < enterFromRightPoint) {
          enterFromRights[i].classList.add('active');
        } else {
          enterFromRights[i].classList.remove('active');
        }
      }
    }

    window.addEventListener('scroll', enterFromRight);

    function slideIn() {
      const slideIns = document.querySelectorAll('.slide-in');
      for (let i = 0; i < slideIns.length; i++) {
        const windowHeight = window.innerHeight;
        const slideInTop = slideIns[i].getBoundingClientRect().top;
        const slideInPoint = windowHeight - 150;
        if (slideInTop < slideInPoint) {
          slideIns[i].classList.add('active');
        } else {
          slideIns[i].classList.remove('active');
        }
      }
    }

    window.addEventListener('scroll', slideIn);
    slideIn();
  }
}
