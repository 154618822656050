import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-preferences',
  templateUrl: './cookie-preferences.component.html',
  styleUrls: ['./cookie-preferences.component.scss']
})
export class CookiePreferencesComponent implements OnInit {

  constructor() { }

    ngOnInit() {
      (function(d, s, id) { var js, tjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) 
        return; js = d.createElement(s); js.id = id; 
        js.src = "https://app.termly.io/embed-policy.min.js";
         tjs.parentNode.insertBefore(js, tjs); }
         (document, 'script', 'termly-jssdk'));
    }
  

}
