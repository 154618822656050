import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Meta, Title } from '@angular/platform-browser';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../shared/common.service';
import { AppConfig } from '../config/app.config';
import { CanonicalService } from '../shared/canonical.service';

@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.scss']
})
export class ReconciliationComponent implements OnInit, AfterViewInit, OnDestroy {

  private unSubscribe = new Subject<boolean>();
  sideTabActive = 0;
  showPlayer = false;
  @ViewChild('sideTabsScroll', {static: false}) sideTabsScroll: ElementRef;
  autoClickObj = {
    stopAutoClick: false,
    setTimeOuts: []
  };

  constructor(
    private router: Router,
    private meta: Meta,
    private title: Title,
    public cmnSrv: CommonService,
    private canonicalService: CanonicalService
  ) {
    this.router.events.pipe(takeUntil(this.unSubscribe)).subscribe((evt: RouterEvent) => {
      this.navigationInterceptor(evt);
    });
  }

  ngOnInit() {
    this.canonicalService.createLinkForCanonicalURL();
  }

  ngAfterViewInit() {
    document.querySelector('app-navbar > header').classList.add('recon');
    if (window.innerWidth >= 992) {
      this.startAutoClick();
    }
  }

  startAutoClick() {
    const tabs = this.sideTabsScroll.nativeElement.querySelectorAll('.tab');
    tabs.forEach((eachElement: HTMLElement, i: number) => {
      const timeOut = setTimeout(() => {
        eachElement.click();
      }, 2000 * i);
      this.autoClickObj.setTimeOuts.push(timeOut);
      if ((tabs.length - 1) === i) {
        const inrTimeOut = setTimeout(() => {
          if (!this.autoClickObj.stopAutoClick) {
            this.startAutoClick();
          }
        }, 2000 * (i + 1));
        this.autoClickObj.setTimeOuts.push(inrTimeOut);
      }
    });
  }

  stopAutoClick(evt) {
    if (evt.screenX && evt.screenX !== 0 && evt.screenY && evt.screenY !== 0) {
      this.autoClickObj.stopAutoClick = true;
      this.autoClickObj.setTimeOuts.forEach(each => clearTimeout(each));
    }
  }

  navigationInterceptor(evt) {
    if (evt instanceof NavigationEnd) {
      if (evt.urlAfterRedirects === '/' + AppConfig.routes.reconciliation) {
        this.title.setTitle('Bank & Credit Card Reconciliation | Framework for Revenue Automation');
        this.meta.updateTag({
          name: 'description',
          content: 'Get all types of reconciliation to enterprise in a single use from Gilbyte. We offer credit card reconcilation, ML/AI Engine, 99% Automated Recon, etc.'
        });
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    document.querySelector('app-navbar > header').classList.remove('recon');
  }

}
