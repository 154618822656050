import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-links-sidebar',
  templateUrl: './links-sidebar.component.html',
  styleUrls: ['./links-sidebar.component.scss']
})
export class LinksSidebarComponent {

  linksList = [
    {
      name: 'Data Capture',
      link: 'data-capture'
    },
    {
      name: 'Reconciliation',
      link: 'reconciliation'
    },
    {
      name: 'Subledger Accounting',
      link: 'accounting'
    },
    {
      name: 'Business Intelligence',
      link: 'dashboard-reports'
    },
    {
      name: 'ERP/EPM Integration',
      link: 'erp-epm'
    }
  ];
  currentRoute;

  constructor(private router: Router) {
    this.router.events.subscribe((val: NavigationEnd) => val instanceof NavigationEnd ? this.currentRoute = val.url : '');
  }

}
