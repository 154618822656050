import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Error404Component } from './error404/error404.component';
import { AppConfig } from './config/app.config';
import { DataModellingComponent } from './data-modelling/data-modelling.component';
import { ReconciliationComponent } from './reconciliation/reconciliation.component';
import { AccountingComponent } from './accounting/accounting.component';
import { ReportsComponent } from './reports/reports.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TeamComponent } from './team/team.component';
import { ErpEpmComponent } from './erp-epm/erp-epm.component';
import { CookieComponent } from './cookie/cookie.component';
import { IndustriesComponent } from './industries/industries.component';
import { ScheduleDemoComponent } from './schedule-demo/schedule-demo.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { CookiePreferencesComponent } from './cookie-preferences/cookie-preferences.component';
import { SlaasComponent } from './slaas/slaas.component';
import { fcmComponent } from './fcm/fcm.component';
import { BiAnalyticsComponent } from './bi-analytics/bi-analytics.component';
import { candcComponent } from './candc/candc.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: AppConfig.routes.error404, component: Error404Component },
  { path: AppConfig.routes.dataCapture, component: DataModellingComponent },
  { path: AppConfig.routes.reconciliation, component: ReconciliationComponent },
  { path: AppConfig.routes.accounting, component: AccountingComponent },
  { path: AppConfig.routes.reports, component: ReportsComponent },
  { path: AppConfig.routes.contactUs, component: ContactUsComponent },
  { path: AppConfig.routes.requestDemo, component: ContactUsComponent },
  { path: AppConfig.routes.scheduleDemo, component: ScheduleDemoComponent },
  { path: AppConfig.routes.team, component: TeamComponent },
  { path: AppConfig.routes.erpEpm, component: ErpEpmComponent },
  { path: AppConfig.routes.industries, component: IndustriesComponent },
  { path: AppConfig.routes.cookie, component: CookieComponent },
  { path: AppConfig.routes.signUp, component: SignUpComponent },
  { path: AppConfig.routes.privacy, component: PrivacyPolicyComponent },
  { path: AppConfig.routes.cookiepolicy, component: CookiePolicyComponent },
  { path: AppConfig.routes.cookiePrefernces, component: CookiePreferencesComponent },
  { path: AppConfig.routes.slaas, component: SlaasComponent },
  { path: AppConfig.routes.fcm, component: fcmComponent },
  { path: AppConfig.routes.bianalytics, component: BiAnalyticsComponent },
  { path: AppConfig.routes.candc, component: candcComponent },
  
  // otherwise redirect to 404
  { path: '**', redirectTo: '/' + AppConfig.routes.error404 }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
